import React, { Suspense } from "react";
import "./global.css";
import { Provider as StoreProvider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import appRoutes from "./routes";
import defaultStore from "./store/configureStore";


const features = [
    {
        name: "We are security-oriented",
        role: "",
        imageUrl: "https://rosync.net/images/undraw_security.svg",
        bio: "We have thought about security in every step of our infrastructure to prevent breaches from happening. All your data is encrypted at rest and is securely transmitted."
    },
    {
        name: "Scale with your success",
        role: "",
        imageUrl: "https://rosync.net/images/undraw_scale.svg",
        bio: "Don't think about databases and server provisioning when you can build amazing experiences! We take care of the work behind the scenes."
    },
    {
        name: "Data redundancy",
        role: "",
        imageUrl: "https://rosync.net/images/undraw_clone.svg",
        bio: "We replicate your data (free of charge) to multiple regions and service providers for peace of mind. Our goal is to never be down!"
    },
    {
        name: "Simple API",
        role: "",
        imageUrl: "https://rosync.net/images/undraw_code.svg",
        bio: "Our API is easy to understand, and we have tutorials you can follow to get you started in less than 5 minutes."
    },
    {
        name: "We are fast",
        role: "",
        imageUrl: "https://rosync.net/images/undraw_fast.svg",
        bio: "Speed is important to ensure your players are thriving in your experiences, so we have picked only the best providers to ensure millisecond response times!"
    },
    {
        name: "Extremely cheap",
        role: "",
        imageUrl: "https://rosync.net/images/undraw_savings.svg",
        bio: "Who said that using a database service needs to be expensive? With Robase there is no need to empty your pockets. Check our pricing!"
    }
];

export default function App (props: any) {
    
    return (
        <StoreProvider store={ defaultStore }>
            <CookiesProvider>
                <BrowserRouter>
                    <Suspense fallback={ <h2>Loading main</h2> }>
                        {
                            renderRoutes(appRoutes)
                        }
                    </Suspense>
                </BrowserRouter>
            </CookiesProvider>
        </StoreProvider>
    );
};
