import { RouteConfig } from "react-router-config";
import LandingSection from "../../views/LandingSection";


const appRoutes: RouteConfig[] = [
    {
        path: "/",
        component: LandingSection
    }
];

export default appRoutes;
