import React from "react";
import { renderRoutes } from "react-router-config";
import { landingSectionRoutes } from "./routes";


export default function LandingSection () {
    return (
        <>
            {
                renderRoutes(landingSectionRoutes)
            }
        </>
    );
};
