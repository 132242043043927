import { RouteConfig } from "react-router-config";
import { lazy } from "react";


export const landingSectionRoutes: RouteConfig[] = [
    {
        path: "/",
        exact: true,
        component: lazy(() => import("./subViews/main/LandingSectionMainView"))
    }
];
